import React, { useRef, useEffect } from 'react';
import { WithContext as ReactTags } from 'react-tag-input';

// Specifies which characters should terminate tags input. An array of character codes.
const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

const InputTag = ({onTagsChange, tags, setTags}) => {
  const tagsContainerRef = useRef(null);

  // Method to delete tag from Array
const handleDelete = (i) => {
  const newTags = tags.filter((tag, index) => index !== i);
  setTags(newTags);
  onTagsChange(newTags);
};

const handleAddition = (tag) => {
  const newTags = [...tags, tag];
  setTags(newTags);
  onTagsChange(newTags);
};

  useEffect(() => {
    const selectedContainer = tagsContainerRef.current.querySelector('.ReactTags__selected');
    if (selectedContainer) {
      selectedContainer.scrollTop = selectedContainer.scrollHeight;
    }
  }, [tags]);

  return (
    <div id="tags" ref={tagsContainerRef}>
      <ReactTags
        tags={tags}
        delimiters={delimiters}
        handleDelete={handleDelete}
        handleAddition={handleAddition}
        inputFieldPosition="bottom"
        allowDragDrop={false}
      />
    </div>
  );
};

export default InputTag;

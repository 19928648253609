import "./App.css";
import React, { useState, useEffect } from "react";
import InputTag from "./InputTag";
import firebase from "firebase/compat/app";
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { getFirestore, doc, setDoc, getDoc, arrayUnion, serverTimestamp } from 'firebase/firestore';

// Assuming you have your Firebase project configuration in a separate file
import firebaseConfig from "./firebaseConfig.js"; // Import your Firebase configuration

function App() {
  const [selectedImage, setSelectedImage] = useState(null);
  const [tags, setTags] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [showThankYou, setShowThankYou] = useState(false);
  const [intro, setIntro] = useState(true);
  const [childTags, setChildTags] = useState([]);
  const [theme, setTheme] = useState('light');

  // Initialize Firebase app (replace with your configuration)
  firebase.initializeApp(firebaseConfig);

  useEffect(() => {
    const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    setTheme(darkModeMediaQuery.matches ? 'dark' : 'light');

    const handleChange = (e) => {
      setTheme(e.matches ? 'dark' : 'light');
    };

    darkModeMediaQuery.addEventListener('change', handleChange);

    return () => {
      darkModeMediaQuery.removeEventListener('change', handleChange);
    };
  }, []);
  
  const handleImageUpload = (event) => {
    setSelectedImage(event.target.files[0]);
  };

  const handleTagsChange = (tags) => {
    setTags(tags);
  };
  const handleSubmit = async () => {
    try {
        setUploading(true);
        console.log('Initializing Firebase Storage and Firestore...');
        console.log('Origin:', window.location.origin);
        const storage = getStorage(firebase.app());
        const firestore = getFirestore(firebase.app());

        const imageName = `${Date.now()}_${selectedImage.name}`;
        const storageRef = ref(storage, `images/${imageName}`);

        // Upload image to Firebase Storage
        await uploadBytes(storageRef, selectedImage);
        console.log('Image uploaded to Firebase Storage.');

        // Get download URL of the uploaded image
        const imageUrl = await getDownloadURL(storageRef);
        console.log('Image URL obtained:', imageUrl);

        // Extract tags from tags array (assuming tags is defined elsewhere)
        const stringTags = tags.map(tag => tag.text);
        console.log('Extracted tags:', stringTags);

        // Update image document in Firestore
        const imageDocRef = doc(firestore, 'images', imageName);
        await setDoc(imageDocRef, {
            imageUrl: imageUrl,
            tags: stringTags, // Store only the 'text' property of tags
            timestamp: serverTimestamp()
        });
        console.log('Image data saved to Firestore.');

        // Update tags collection in Firestore
        for (const tag of stringTags) {
            console.log(`Processing tag: ${tag}`);
            const tagDocRef = doc(firestore, 'tags', tag);
            await setDoc(tagDocRef, {
                images: arrayUnion(imageName)
            }, { merge: true });
            console.log(`Tag ${tag} updated in Firestore.`);
        }

        // Verification Step: Read back the image data from Firestore
        const imageDocSnap = await getDoc(imageDocRef);
        if (imageDocSnap.exists()) {
            console.log('Verification: Image data read back from Firestore:', imageDocSnap.data());

            // Reset UI state after successful upload
            setUploading(false);
            setShowThankYou(true);
            setTags([]);
            setChildTags([]);

            setTimeout(() => {
                setShowThankYou(false);
            }, 2000);

        }

        // Reset selected image and tags after successful upload
        setSelectedImage(null);
        setTags([]);

    } catch (error) {
        console.error('Error uploading image:', error);
        alert('Error uploading image. Please try again.');
    }
};


  return (
    <div className="App" data-theme={theme}>
      <h1>Hadron IAT-1</h1>
      <div className="imageContainer">
        {!selectedImage && (
          <div>
            <label htmlFor="file-upload" className="upload-button">
              Upload Image
            </label>
            <input
              id="file-upload"
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
            />
          </div>
        )}
        {selectedImage && (
          <img
            src={URL.createObjectURL(selectedImage)}
            alt="Selected"
            className="uploaded-image"
          />
        )}
      </div>
      <p>
        Enter Image Tags below. Try to capture the essence of your work. Use
        specifics and broad description. Thank You very much for your help &lt;3{" "}
      </p>
      <div className="inputContainer">
        <InputTag onTagsChange={handleTagsChange} tags={childTags} setTags={setChildTags}/>
        <br />
        <button
          className="submit-button"
          onClick={handleSubmit}
          disabled={!selectedImage || tags.length === 0}
        >
          Submit
        </button>
      </div>
      {uploading && (
        <div className="fullscreen-overlay">
          <div className="overlay-content">
            <span role="img" aria-label="uploading">⏳</span>
            <h1>Uploading...</h1>
          </div>
        </div>
      )}

      {showThankYou && (
        <div className="fullscreen-overlay">
          <div className="overlay-content">
            <span role="img" aria-label="thumbs-up">👍</span>
            <h1>Thank you!</h1>
          </div>
        </div>
      )}

{intro && (
        <div className="fullscreen-overlay">
          <div className="overlay-content">
            <span role="img" aria-label="wave">👋</span>
            <h1 style={{ lineHeight: '1.6' }}>
              Hey, thank you for taking the time and helping us build our project. <br/>
                The goal is to get a better understanding of portofolio images, and how you would describe your work.<br/>
                Please upload an image and add tags that describe your work. <br/>
                All rights to the images will remain with you - we will not use them for any other purpose.<br/> 
                Thank you for your help. <br/><br/> 
                Much Love, <br/>
                Aatos & Johannes
            </h1>
            <button
              className="submit-button"
              onClick={() => setIntro(false)} >
              Got it! Let's go!
              </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;

const firebaseConfig = {
    apiKey: "AIzaSyDeM3qi3hGptqP6VUHsbvXzvio5gHIZqkg",
    authDomain: "hadron-519f7.firebaseapp.com",
    projectId: "hadron-519f7",
    storageBucket: "hadron-519f7.appspot.com",
    messagingSenderId: "430520548115",
    appId: "1:430520548115:web:97c76f3e1c0b8f38a8f8e6",
    measurementId: "G-M55B20617T"
  };

export default firebaseConfig;